import { defineStore } from 'pinia'
import { ref } from 'vue'
import TableSettings from '@/types/tableSettings'
import ConstructionSiteRegistrationModel from '@/models/ConstructionSiteRegistrationModel'
import { notification } from 'ant-design-vue'
import { WrappedResponse } from 'vue-api-query'

export const constructionSiteRegistrationStore = defineStore('constructionSiteRegistration', () => {
  const loading = ref({ list: false })
  const constructionSiteRegistrationsList = ref<WrappedResponse<ConstructionSiteRegistrationModel[]>|ConstructionSiteRegistrationModel[]>({
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  })

  function getConstructionSiteRegistrations (tableSettings:TableSettings, projectId:number) : void {
    loading.value.list = true
    let constructionSiteRegistrationModel = new ConstructionSiteRegistrationModel()

    // Add filters if there are any filters set.
    if (tableSettings.activeFilters) {
      Object.keys(tableSettings.activeFilters).forEach(key => {
        if (tableSettings.activeFilters[key].length) {
          constructionSiteRegistrationModel = constructionSiteRegistrationModel.whereIn(key, tableSettings.activeFilters[key])
        }
      })
    }

    constructionSiteRegistrationModel = constructionSiteRegistrationModel.where('project.id', projectId)

    // Add search if available.
    if (tableSettings.search) {
      constructionSiteRegistrationModel = constructionSiteRegistrationModel.where('search', tableSettings.search)
    }

    // Add orderBy if sort is set.
    if (tableSettings.sort && tableSettings.sort.order && tableSettings.sort.columnKey) {
      constructionSiteRegistrationModel = constructionSiteRegistrationModel.orderBy(tableSettings.sort.order === 'ascend' ? tableSettings.sort.columnKey : '-' + tableSettings.sort.columnKey)
    }

    if (tableSettings.pagination) {
      constructionSiteRegistrationModel = constructionSiteRegistrationModel.limit(tableSettings.pagination.pageSize).page(tableSettings.pagination.current)
    }

    constructionSiteRegistrationModel.get().then((items) => {
      constructionSiteRegistrationsList.value = items
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het ophalen van de bouwplaatsregistraties!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    }).finally(() => {
      loading.value.list = false
    })
  }

  return { constructionSiteRegistrationsList, loading, getConstructionSiteRegistrations }
})
